import { NgModule } from '@angular/core';

import { DictionariesService } from '@dsh/api-codegen/capi';
import { ErrorModule } from '@dsh/app/shared';

@NgModule({
    imports: [ErrorModule],
    providers: [DictionariesService],
})
export class CountriesModule {}
