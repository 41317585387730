/**
 * RBKmoney URL shortener API
 * URL shortener API 
 *
 * OpenAPI spec version: 1.0
 * Contact: tech-support@rbkmoney.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { InlineResponse400 } from '../model/inlineResponse400';
import { ShortenedUrl } from '../model/shortenedUrl';
import { ShortenedUrlParams } from '../model/shortenedUrlParams';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ShortenerService {

    protected basePath = 'https://rbk.mn/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Удалить короткую ссылку
     * @param xRequestID Уникальный идентификатор запроса к системе
     * @param shortenedUrlID Идентификатор короткой ссылки
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteShortenedUrl(xRequestID: string, shortenedUrlID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteShortenedUrl(xRequestID: string, shortenedUrlID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteShortenedUrl(xRequestID: string, shortenedUrlID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteShortenedUrl(xRequestID: string, shortenedUrlID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xRequestID === null || xRequestID === undefined) {
            throw new Error('Required parameter xRequestID was null or undefined when calling deleteShortenedUrl.');
        }

        if (shortenedUrlID === null || shortenedUrlID === undefined) {
            throw new Error('Required parameter shortenedUrlID was null or undefined when calling deleteShortenedUrl.');
        }

        let headers = this.defaultHeaders;
        if (xRequestID !== undefined && xRequestID !== null) {
            headers = headers.set('X-Request-ID', String(xRequestID));
        }

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/shortened-urls/${encodeURIComponent(String(shortenedUrlID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Получить данные созданной короткой ссылки
     * @param xRequestID Уникальный идентификатор запроса к системе
     * @param shortenedUrlID Идентификатор короткой ссылки
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShortenedUrl(xRequestID: string, shortenedUrlID: string, observe?: 'body', reportProgress?: boolean): Observable<ShortenedUrl>;
    public getShortenedUrl(xRequestID: string, shortenedUrlID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortenedUrl>>;
    public getShortenedUrl(xRequestID: string, shortenedUrlID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortenedUrl>>;
    public getShortenedUrl(xRequestID: string, shortenedUrlID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xRequestID === null || xRequestID === undefined) {
            throw new Error('Required parameter xRequestID was null or undefined when calling getShortenedUrl.');
        }

        if (shortenedUrlID === null || shortenedUrlID === undefined) {
            throw new Error('Required parameter shortenedUrlID was null or undefined when calling getShortenedUrl.');
        }

        let headers = this.defaultHeaders;
        if (xRequestID !== undefined && xRequestID !== null) {
            headers = headers.set('X-Request-ID', String(xRequestID));
        }

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];

        return this.httpClient.get<ShortenedUrl>(`${this.basePath}/shortened-urls/${encodeURIComponent(String(shortenedUrlID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Создать новую короткую ссылку
     * @param xRequestID Уникальный идентификатор запроса к системе
     * @param shortenedUrlParams Параметры для создания короткой ссылки
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shortenUrl(xRequestID: string, shortenedUrlParams: ShortenedUrlParams, observe?: 'body', reportProgress?: boolean): Observable<ShortenedUrl>;
    public shortenUrl(xRequestID: string, shortenedUrlParams: ShortenedUrlParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortenedUrl>>;
    public shortenUrl(xRequestID: string, shortenedUrlParams: ShortenedUrlParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortenedUrl>>;
    public shortenUrl(xRequestID: string, shortenedUrlParams: ShortenedUrlParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xRequestID === null || xRequestID === undefined) {
            throw new Error('Required parameter xRequestID was null or undefined when calling shortenUrl.');
        }

        if (shortenedUrlParams === null || shortenedUrlParams === undefined) {
            throw new Error('Required parameter shortenedUrlParams was null or undefined when calling shortenUrl.');
        }

        let headers = this.defaultHeaders;
        if (xRequestID !== undefined && xRequestID !== null) {
            headers = headers.set('X-Request-ID', String(xRequestID));
        }

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json; charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ShortenedUrl>(`${this.basePath}/shortened-urls`,
            shortenedUrlParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
