import { NgModule } from '@angular/core';

import { MobileMenuModule } from './mobile-menu.module';
import { XpayMobileMenuComponent } from './xpay-mobile-menu.component';

@NgModule({
    imports: [MobileMenuModule],
    declarations: [XpayMobileMenuComponent],
    exports: [XpayMobileMenuComponent],
})
export class XpayMobileMenuModule {}
