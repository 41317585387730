import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoaderService } from '../../loader/loader.service';

export const NOT_SHOW_LOADER: string[] = [
    'assets',
    'analytics/payments',
    'analytics/refunds',
    'processing/shops',
    'analytics/balances/current-shop-balances',
    'reports?partyID',
    'payments?partyID',
    'processing/claims',
    'refunds?partyID',
    'invoices?partyID',
    'webhooks',
    'shops',
    'membership',
    'invitations',
    'orgs',
];

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(private loaderService: LoaderService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.loaderService.isLoaderEnabled) return next.handle(req);

        let isShowLoader: boolean = true;

        for (const path of NOT_SHOW_LOADER) {
            if (req.urlWithParams.includes(path)) {
                isShowLoader = false;
                break;
            }
        }
        if (isShowLoader) this.loaderService.showLoader();
        return next.handle(req).pipe(
            finalize(() => {
                if (isShowLoader) this.loaderService.hideLoader();
            })
        );
    }
}
