import { NgModule } from '@angular/core';

import { ContextService } from './context.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [ContextService],
})
export class ContextModule {}
