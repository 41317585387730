import { NgModule } from '@angular/core';

import { SpinnerModule } from '@dsh/components/indicators';

import { HomeModule } from './home.module';
import { XpayMobileGridModule } from './mobile-grid/xpay-mobile-grid.module';
import { XpayHomeComponent } from './xpay-home.component';

@NgModule({
    imports: [HomeModule, XpayMobileGridModule, SpinnerModule],
    declarations: [XpayHomeComponent],
    exports: [XpayHomeComponent],
})
export class XpayHomeModule {}
