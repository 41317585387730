import { Injectable } from '@angular/core';
import { IdGeneratorService } from '@xpay_rbk/id-generator';
import { Observable, combineLatest } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';

import {
    AnalyticsService as APIAnalyticsService,
    InlineResponse200,
    InlineResponse2001,
    InlineResponse2002,
    InlineResponse2003,
    InlineResponse2004,
    InlineResponse2005,
    InlineResponse2006,
    InlineResponse2007,
    SplitUnit,
} from '@dsh/api-codegen/anapi';
import { PaymentInstitution } from '@dsh/api-codegen/capi';
import { RoleId } from '@dsh/api-codegen/organizations';
import { KeycloakTokenInfoService } from '@dsh/app/shared/services';
import { OrganizationManagementService } from '@dsh/app/shared/services/organization-management';
import { shareReplayRefCount } from '@dsh/operators';

import { ApiShopsService } from '../shop';
import { toDateLike } from '../utils';

import RealmEnum = PaymentInstitution.RealmEnum;

@Injectable()
export class AnalyticsService {
    private partyID$: Observable<string> = this.organizationManagementService.organization$.pipe(
        map((org) => org.party)
    );
    private getManagersFullShops: Observable<string[]> = this.organizationManagementService.currentMember$.pipe(
        map((member) => {
            return member.roles.filter((role) => role.roleId === RoleId.Manager).map((role) => role.scope.resourceId);
        }),
        shareReplayRefCount()
    );

    constructor(
        private analyticsService: APIAnalyticsService,
        private keycloakTokenInfoService: KeycloakTokenInfoService,
        private idGenerator: IdGeneratorService,
        private organizationManagementService: OrganizationManagementService,
        private apiShopsService: ApiShopsService
    ) {}

    getAveragePayment(
        fromTime: string,
        toTime: string,
        params: {
            shopIDs?: string[];
            excludeShopIDs?: string[];
            paymentInstitutionRealm?: RealmEnum;
        }
    ): Observable<InlineResponse2001> {
        return this.organizationManagementService.isOrganizationManager$.pipe(
            switchMap((isManager) => {
                if (isManager && !params.shopIDs) {
                    return combineLatest([this.partyID$, this.getManagersFullShops]).pipe(
                        switchMap(([partyID, shops]) => {
                            return this.analyticsService.getAveragePayment(
                                this.idGenerator.shortUuid(),
                                partyID,
                                toDateLike(fromTime),
                                toDateLike(toTime),
                                undefined,
                                shops,
                                params.excludeShopIDs,
                                params.paymentInstitutionRealm
                            );
                        })
                    );
                }

                return this.partyID$.pipe(
                    switchMap((partyID) =>
                        this.analyticsService.getAveragePayment(
                            this.idGenerator.shortUuid(),
                            partyID,
                            toDateLike(fromTime),
                            toDateLike(toTime),
                            undefined,
                            params.shopIDs,
                            params.excludeShopIDs,
                            params.paymentInstitutionRealm
                        )
                    )
                );
            }),
            take(1)
        );
    }

    getPaymentsAmount(
        fromTime: string,
        toTime: string,
        params: {
            shopIDs?: string[];
            excludeShopIDs?: string[];
            paymentInstitutionRealm?: RealmEnum;
        }
    ): Observable<InlineResponse2001> {
        return this.organizationManagementService.isOrganizationManager$.pipe(
            switchMap((isManager) => {
                if (isManager && !params.shopIDs) {
                    return combineLatest([this.partyID$, this.getManagersFullShops]).pipe(
                        switchMap(([partyID, shops]) => {
                            return this.analyticsService.getPaymentsAmount(
                                this.idGenerator.shortUuid(),
                                partyID,
                                toDateLike(fromTime),
                                toDateLike(toTime),
                                undefined,
                                shops,
                                params.excludeShopIDs,
                                params.paymentInstitutionRealm
                            );
                        })
                    );
                }

                return this.partyID$.pipe(
                    switchMap((partyID) =>
                        this.analyticsService.getPaymentsAmount(
                            this.idGenerator.shortUuid(),
                            partyID,
                            toDateLike(fromTime),
                            toDateLike(toTime),
                            undefined,
                            params.shopIDs,
                            params.excludeShopIDs,
                            params.paymentInstitutionRealm
                        )
                    )
                );
            }),
            take(1)
        );
    }

    getPaymentsCount(
        fromTime: string,
        toTime: string,
        params: {
            shopIDs?: string[];
            excludeShopIDs?: string[];
            paymentInstitutionRealm?: RealmEnum;
        }
    ): Observable<InlineResponse2003> {
        return this.organizationManagementService.isOrganizationManager$.pipe(
            switchMap((isManager) => {
                if (isManager && !params.shopIDs) {
                    return combineLatest([this.partyID$, this.getManagersFullShops]).pipe(
                        switchMap(([partyID, shops]) => {
                            return this.analyticsService.getPaymentsCount(
                                this.idGenerator.shortUuid(),
                                partyID,
                                toDateLike(fromTime),
                                toDateLike(toTime),
                                undefined,
                                shops,
                                params.excludeShopIDs,
                                params.paymentInstitutionRealm
                            );
                        })
                    );
                }

                return this.partyID$.pipe(
                    switchMap((partyID) =>
                        this.analyticsService.getPaymentsCount(
                            this.idGenerator.shortUuid(),
                            partyID,
                            toDateLike(fromTime),
                            toDateLike(toTime),
                            undefined,
                            params.shopIDs,
                            params.excludeShopIDs,
                            params.paymentInstitutionRealm
                        )
                    )
                );
            }),
            take(1)
        );
    }

    getPaymentsErrorDistribution(
        fromTime: string,
        toTime: string,
        params: {
            shopIDs?: string[];
            excludeShopIDs?: string[];
            paymentInstitutionRealm?: RealmEnum;
        }
    ): Observable<InlineResponse2004> {
        return this.organizationManagementService.isOrganizationManager$.pipe(
            switchMap((isManager) => {
                if (isManager && !params.shopIDs) {
                    return combineLatest([this.partyID$, this.getManagersFullShops]).pipe(
                        switchMap(([partyID, shops]) => {
                            return this.analyticsService.getPaymentsErrorDistribution(
                                this.idGenerator.shortUuid(),
                                partyID,
                                toDateLike(fromTime),
                                toDateLike(toTime),
                                undefined,
                                shops,
                                params.excludeShopIDs,
                                params.paymentInstitutionRealm
                            );
                        })
                    );
                }

                return this.partyID$.pipe(
                    switchMap((partyID) =>
                        this.analyticsService.getPaymentsErrorDistribution(
                            this.idGenerator.shortUuid(),
                            partyID,
                            toDateLike(fromTime),
                            toDateLike(toTime),
                            undefined,
                            params.shopIDs,
                            params.excludeShopIDs,
                            params.paymentInstitutionRealm
                        )
                    )
                );
            }),
            take(1)
        );
    }

    getPaymentsSubErrorDistribution(
        fromTime: string,
        toTime: string,
        params: {
            shopIDs?: string[];
            excludeShopIDs?: string[];
            paymentInstitutionRealm?: RealmEnum;
        }
    ): Observable<InlineResponse2007> {
        return this.organizationManagementService.isOrganizationManager$.pipe(
            switchMap((isManager) => {
                if (isManager && !params.shopIDs) {
                    return combineLatest([this.partyID$, this.getManagersFullShops]).pipe(
                        switchMap(([partyID, shops]) => {
                            return this.analyticsService.getPaymentsSubErrorDistribution(
                                this.idGenerator.shortUuid(),
                                partyID,
                                toDateLike(fromTime),
                                toDateLike(toTime),
                                undefined,
                                shops,
                                params.excludeShopIDs,
                                params.paymentInstitutionRealm
                            );
                        })
                    );
                }

                return this.partyID$.pipe(
                    switchMap((partyID) =>
                        this.analyticsService.getPaymentsSubErrorDistribution(
                            this.idGenerator.shortUuid(),
                            partyID,
                            toDateLike(fromTime),
                            toDateLike(toTime),
                            undefined,
                            params.shopIDs,
                            params.excludeShopIDs,
                            params.paymentInstitutionRealm
                        )
                    )
                );
            }),
            take(1)
        );
    }

    getPaymentsToolDistribution(
        fromTime: string,
        toTime: string,
        params: {
            shopIDs?: string[];
            excludeShopIDs?: string[];
            paymentInstitutionRealm?: RealmEnum;
        }
    ): Observable<InlineResponse2002> {
        return this.organizationManagementService.isOrganizationManager$.pipe(
            switchMap((isManager) => {
                if (isManager && !params.shopIDs) {
                    return combineLatest([this.partyID$, this.getManagersFullShops]).pipe(
                        switchMap(([partyID, shops]) => {
                            return this.analyticsService.getPaymentsToolDistribution(
                                this.idGenerator.shortUuid(),
                                partyID,
                                toDateLike(fromTime),
                                toDateLike(toTime),
                                undefined,
                                shops,
                                params.excludeShopIDs,
                                params.paymentInstitutionRealm
                            );
                        })
                    );
                }

                return this.partyID$.pipe(
                    switchMap((partyID) =>
                        this.analyticsService.getPaymentsToolDistribution(
                            this.idGenerator.shortUuid(),
                            partyID,
                            toDateLike(fromTime),
                            toDateLike(toTime),
                            undefined,
                            params.shopIDs,
                            params.excludeShopIDs,
                            params.paymentInstitutionRealm
                        )
                    )
                );
            }),
            take(1)
        );
    }

    getRefundsAmount(
        fromTime: string,
        toTime: string,
        params: {
            shopIDs?: string[];
            excludeShopIDs?: string[];
            paymentInstitutionRealm?: RealmEnum;
        }
    ): Observable<InlineResponse2001> {
        return this.organizationManagementService.isOrganizationManager$.pipe(
            switchMap((isManager) => {
                if (isManager && !params.shopIDs) {
                    return combineLatest([this.partyID$, this.getManagersFullShops]).pipe(
                        switchMap(([partyID, shops]) => {
                            return this.analyticsService.getRefundsAmount(
                                this.idGenerator.shortUuid(),
                                partyID,
                                toDateLike(fromTime),
                                toDateLike(toTime),
                                undefined,
                                shops,
                                params.excludeShopIDs,
                                params.paymentInstitutionRealm
                            );
                        })
                    );
                }

                return this.partyID$.pipe(
                    switchMap((partyID) =>
                        this.analyticsService.getRefundsAmount(
                            this.idGenerator.shortUuid(),
                            partyID,
                            toDateLike(fromTime),
                            toDateLike(toTime),
                            undefined,
                            params.shopIDs,
                            params.excludeShopIDs,
                            params.paymentInstitutionRealm
                        )
                    )
                );
            }),
            take(1)
        );
    }

    getPaymentsSplitAmount(
        fromTime: string,
        toTime: string,
        splitUnit: SplitUnit,
        params: {
            shopIDs?: string[];
            excludeShopIDs?: string[];
            paymentInstitutionRealm?: RealmEnum;
        }
    ): Observable<InlineResponse2005> {
        return this.organizationManagementService.isOrganizationManager$.pipe(
            switchMap((isManager) => {
                if (isManager && !params.shopIDs) {
                    return combineLatest([this.partyID$, this.getManagersFullShops]).pipe(
                        switchMap(([partyID, shops]) => {
                            return this.analyticsService.getPaymentsSplitAmount(
                                this.idGenerator.shortUuid(),
                                partyID,
                                toDateLike(fromTime),
                                toDateLike(toTime),
                                splitUnit,
                                undefined,
                                shops,
                                params.excludeShopIDs,
                                params.paymentInstitutionRealm
                            );
                        })
                    );
                }

                return this.partyID$.pipe(
                    switchMap((partyID) =>
                        this.analyticsService.getPaymentsSplitAmount(
                            this.idGenerator.shortUuid(),
                            partyID,
                            toDateLike(fromTime),
                            toDateLike(toTime),
                            splitUnit,
                            undefined,
                            params.shopIDs,
                            params.excludeShopIDs,
                            params.paymentInstitutionRealm
                        )
                    )
                );
            }),
            take(1)
        );
    }

    getPaymentsSplitCount(
        fromTime: string,
        toTime: string,
        splitUnit: SplitUnit,
        params: {
            shopIDs?: string[];
            excludeShopIDs?: string[];
            paymentInstitutionRealm?: RealmEnum;
        }
    ): Observable<InlineResponse2006> {
        return this.organizationManagementService.isOrganizationManager$.pipe(
            switchMap((isManager) => {
                if (isManager && !params.shopIDs) {
                    return combineLatest([this.partyID$, this.getManagersFullShops]).pipe(
                        switchMap(([partyID, shops]) => {
                            return this.analyticsService.getPaymentsSplitCount(
                                this.idGenerator.shortUuid(),
                                partyID,
                                toDateLike(fromTime),
                                toDateLike(toTime),
                                splitUnit,
                                undefined,
                                shops,
                                params.excludeShopIDs,
                                params.paymentInstitutionRealm
                            );
                        })
                    );
                }

                return this.partyID$.pipe(
                    switchMap((partyID) =>
                        this.analyticsService.getPaymentsSplitCount(
                            this.idGenerator.shortUuid(),
                            partyID,
                            toDateLike(fromTime),
                            toDateLike(toTime),
                            splitUnit,
                            undefined,
                            params.shopIDs,
                            params.excludeShopIDs,
                            params.paymentInstitutionRealm
                        )
                    )
                );
            }),
            take(1)
        );
    }

    getCurrentBalances(params: {
        shopIDs?: string[];
        excludeShopIDs?: string[];
        paymentInstitutionRealm?: RealmEnum;
    }): Observable<InlineResponse2001> {
        return this.organizationManagementService.isOrganizationManager$.pipe(
            switchMap((isManager) => {
                if (isManager && !params.shopIDs) {
                    return combineLatest([this.partyID$, this.getManagersFullShops]).pipe(
                        switchMap(([partyID, shops]) => {
                            return this.analyticsService.getCurrentBalances(
                                this.idGenerator.shortUuid(),
                                partyID,
                                undefined,
                                shops,
                                params.excludeShopIDs,
                                params.paymentInstitutionRealm
                            );
                        })
                    );
                }

                return this.partyID$.pipe(
                    switchMap((partyID) =>
                        this.analyticsService.getCurrentBalances(
                            this.idGenerator.shortUuid(),
                            partyID,
                            undefined,
                            params.shopIDs,
                            params.excludeShopIDs,
                            params.paymentInstitutionRealm
                        )
                    )
                );
            }),
            take(1)
        );
    }

    getGroupBalances(params: { shopIDs?: string[]; excludeShopIDs?: string[] }): Observable<InlineResponse200> {
        return this.organizationManagementService.isOrganizationManager$.pipe(
            switchMap((isManager) => {
                if (isManager && !params.shopIDs) {
                    return combineLatest([this.partyID$, this.getManagersFullShops]).pipe(
                        switchMap(([partyID, shops]) => {
                            return this.analyticsService.getCurrentBalancesGroupByShop(
                                this.idGenerator.shortUuid(),
                                partyID,
                                undefined,
                                shops,
                                params.excludeShopIDs
                            );
                        })
                    );
                }

                return this.partyID$.pipe(
                    switchMap((partyID) =>
                        this.analyticsService.getCurrentBalancesGroupByShop(
                            this.idGenerator.shortUuid(),
                            partyID,
                            undefined,
                            params.shopIDs,
                            params.excludeShopIDs
                        )
                    )
                );
            }),
            take(1)
        );
    }
}
