import {
    Modification,
    ShopModification,
    ShopAccountCreationModification,
    InternationalBankAccount,
} from '@dsh/api-codegen/claim-management';

import {
    createContractCreationModification,
    createShopCreationModification,
    makeShopLocation,
    createInternationalLegalEntityModification,
} from '../claim-party-modification';
import { createBaseShopModification } from '../claim-party-modification/claim-shop-modification/create-base-shop-modification';

import ShopModificationTypeEnum = ShopModification.ShopModificationTypeEnum;

import { createInternationalContractPayoutToolModification } from '../claim-party-modification/claim-contract-modification/create-international-contract-payout-tool-modification';

const TEST_SHOP: Required<Pick<InternationalBankAccount, 'iban' | 'number' | 'bank'>> = {
    iban: '00000000000000000000000',
    number: '',
    bank: {
        abaRtn: '',
        address: 'Test shop address',
        bic: '00000000000',
        name: 'Test shop name',
        country: 'BLR',
    },
};

export const createTestShopClaimChangeset = (
    testShopID: string,
    testContractID: string,
    testPayoutToolID: string,
    testContractorID: string
): Modification[] => {
    return [
        createInternationalLegalEntityModification(testContractorID, {
            country: 'BLR',
            legalName: 'Test legal name',
            registeredAddress: 'Test registered address',
            tradingName: 'Test trading name',
            actualAddress: 'Test actual address',
            registeredNumber: '0000000000000',
        }),
        createContractCreationModification(testContractID, {
            contractorID: testContractorID,
            paymentInstitution: { id: 1 },
        }),
        createInternationalContractPayoutToolModification(testContractID, testPayoutToolID, 'BYN', {
            ...TEST_SHOP,
            correspondentAccount: null,
        }),
        createShopCreationModification(testShopID, {
            category: { categoryID: 1 },
            location: makeShopLocation({ url: 'http://test.url' }),
            details: { name: 'Test shop' },
            contractID: testContractID,
            payoutToolID: testPayoutToolID,
        }),
        createBaseShopModification({
            id: testShopID,
            modification: {
                shopModificationType: ShopModificationTypeEnum.ShopAccountCreationModification,
                currency: { symbolicCode: 'BYN' },
            } as ShopAccountCreationModification,
        }),
    ];
};
