<ng-container *transloco="let t; scope: 'feedback'; read: 'feedback.dialog'">
    <dsh-base-dialog [title]="t('title')" (cancel)="cancel()">
        <div fxLayout="column">
            <mat-form-field>
                <textarea
                    aria-label="message"
                    required
                    [maxlength]="maxLength"
                    [placeholder]="t('message')"
                    [formControl]="messageControl"
                    matInput
                    rows="6"
                ></textarea>
                <mat-hint>{{ messageControl.value?.length || 0 }} / {{ maxLength }}</mat-hint>
            </mat-form-field>
        </div>
        <ng-container *transloco="let c" dshBaseDialogActions>
            <button
                dsh-button
                (click)="send()"
                color="accent"
                [disabled]="messageControl.invalid || (inProgress$ | async)"
            >
                {{ c('send') }}
            </button>
        </ng-container>
    </dsh-base-dialog>
</ng-container>
