<div
    *transloco="let t; scope: 'api-model-details'; read: 'apiModelDetails.payoutToolDetails.wallet'"
    fxLayout="column"
    fxLayoutGap="24px"
>
    <dsh-details-item [title]="t('walletName')">
        <dsh-link-label (click)="goToWalletDetails(wallet?.walletID)">{{
            wallet?.walletID | walletDetails
        }}</dsh-link-label>
    </dsh-details-item>
</div>
