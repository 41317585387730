import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ShopType } from '@dsh/app/shared/components/shop-creation/components/create-shop-dialog/types/shop-type';

import { IntegrationsEnum } from './integrations-enum';

@Injectable()
export class IntegrationService {
    integration: IntegrationsEnum;
    selectedShopType$: BehaviorSubject<ShopType> = new BehaviorSubject(null);

    init(integration: string): void {
        switch (integration) {
            case IntegrationsEnum.Rbkmoney:
                this.integration = IntegrationsEnum.Rbkmoney;
                break;
            case IntegrationsEnum.Xpay:
                this.integration = IntegrationsEnum.Xpay;
                break;
            default:
                console.error(`Unknown integration: ${integration}`);
        }
    }
}
