/**
 * RBKmoney Questionary Resource API
 *  RBKmoney Questionary Resource API для работы с анкетами. 
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@rbk.money
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface IndividualEntity { 
    individualEntityType: IndividualEntity.IndividualEntityTypeEnum;
}
export namespace IndividualEntity {
    export type IndividualEntityTypeEnum = 'RussianIndividualEntity';
    export const IndividualEntityTypeEnum = {
        RussianIndividualEntity: 'RussianIndividualEntity' as IndividualEntityTypeEnum
    };
}
