import { Component, ChangeDetectionStrategy, ApplicationRef } from '@angular/core';

import { LanguageService, Language } from '../../../../language';

@Component({
    selector: 'dsh-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent {
    selectedLanguage: string;
    languages: string[] = this.translationService.getAvailableLanguages();

    constructor(private translationService: LanguageService, private appRef: ApplicationRef) {
        this.selectedLanguage = translationService.active;
    }

    changeLanguage(): void {
        void this.translationService.change(this.selectedLanguage as Language);
        setTimeout(() => this.appRef.tick());
    }
}
