<div class="dsh-mobile-menu" fxLayout="column">
    <div class="dsh-enu-nav-items" fxFlex fxLayout="column" fxLayoutGap="16px">
        <!--        <dsh-mobile-menu-nav-item-->
        <!--            *ngFor="let link of sectionLinks$ | async"-->
        <!--            [routerLink]="link.path"-->
        <!--            routerLinkActive-->
        <!--            [routerLinkActiveOptions]="{ exact: link?.exact }"-->
        <!--            #rla="routerLinkActive"-->
        <!--            [active]="rla.isActive"-->
        <!--            (click)="menuItemSelected.emit()"-->
        <!--            >{{ link.label }}</dsh-mobile-menu-nav-item-->
        <!--        >-->
        <dsh-mobile-menu-nav-item
            [routerLink]="['payment-section', 'realm', realmChange$ | async, 'shops']"
            *transloco="let t; scope: 'toolbar'; read: 'toolbar.nav'"
            >{{ t('payments') }}</dsh-mobile-menu-nav-item
        >
        <div class="sub-menu">
            <dsh-mobile-menu-nav-item
                *ngFor="let item of navbarItemConfig$ | async"
                [active]="isActive(item.routerLink)"
                (click)="setActiveSection(item.routerLink)"
                >{{ item.label }}
            </dsh-mobile-menu-nav-item>
            <dsh-mobile-menu-nav-item
                *transloco="let t; scope: 'payment-section'; read: 'paymentSection.nav'"
                (click)="testEnvToggle()"
                [active]="isActive('realm/test')"
                >{{ t('testEnv') }}
            </dsh-mobile-menu-nav-item>
        </div>

        <!--
        <dsh-mobile-menu-nav-item>Кошельки</dsh-mobile-menu-nav-item>
        <div class="sub-menu">
            <dsh-mobile-menu-nav-item routerLink="/wallet-section/wallets">Кошельки</dsh-mobile-menu-nav-item>
            <dsh-mobile-menu-nav-item routerLink="/wallet-section/deposits">Пополнения</dsh-mobile-menu-nav-item>
            <dsh-mobile-menu-nav-item routerLink="/wallet-section/withdrawals">Выводы</dsh-mobile-menu-nav-item>
            <dsh-mobile-menu-nav-item routerLink="/wallet-section/integrations/webhooks"
                >Интеграция</dsh-mobile-menu-nav-item
            >
        </div>
-->

        <dsh-mobile-menu-nav-item routerLink="/claim-section/claims">Заявки</dsh-mobile-menu-nav-item>
        <hr />
        <dsh-mobile-menu-feedback-item></dsh-mobile-menu-feedback-item>
    </div>
    <mat-divider></mat-divider>
    <dsh-mobile-user-bar></dsh-mobile-user-bar>
</div>
