import { Injectable } from '@angular/core';
import { IdGeneratorService } from '@xpay_rbk/id-generator';
import { Observable, forkJoin, combineLatest } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { Webhook, WebhooksService as ApiWebhooksService } from '@dsh/api-codegen/capi';
import { RoleId } from '@dsh/api-codegen/organizations';
import { OrganizationManagementService } from '@dsh/app/shared/services/organization-management';

@Injectable()
export class WebhooksService {
    constructor(
        private apiWebhooksService: ApiWebhooksService,
        private idGenerator: IdGeneratorService,
        private organizationManagementService: OrganizationManagementService
    ) {}

    createWebhook(params: Webhook): Observable<Webhook> {
        return this.organizationManagementService.organization$.pipe(
            switchMap((org) =>
                this.apiWebhooksService.createWebhook(this.idGenerator.shortUuid(), { ...params, partyID: org.party })
            )
        );
    }

    getWebhooks(): Observable<Webhook[]> {
        return combineLatest([
            this.organizationManagementService.organization$,
            this.organizationManagementService.isOrganizationIntegrator$,
            this.organizationManagementService.currentMember$,
        ]).pipe(
            switchMap(([org, isIntegrator, member]) => {
                if (isIntegrator) {
                    const shopsIds = [
                        ...new Set(
                            member.roles
                                .filter((role) => role.roleId === RoleId.Integrator)
                                .map((role) => role.scope.resourceId)
                        ),
                    ];
                    const observables = shopsIds.map((shopId) =>
                        this.apiWebhooksService.getWebhooksForPartyAndShop(
                            this.idGenerator.shortUuid(),
                            org.party,
                            shopId
                        )
                    );
                    return forkJoin(observables).pipe(
                        map((data) => data.reduce((hooks, acc) => [...acc, ...hooks], []))
                    );
                }
                return this.apiWebhooksService.getWebhooksForParty(this.idGenerator.shortUuid(), org.party);
            })
        );
    }

    getWebhookByID(webhookID: string): Observable<Webhook> {
        return this.organizationManagementService.organization$.pipe(
            switchMap((org) =>
                this.apiWebhooksService.getWebhookByIDForParty(this.idGenerator.shortUuid(), org.party, webhookID)
            )
        );
    }

    deleteWebhookByID(webhookID: string): Observable<any> {
        return this.organizationManagementService.organization$.pipe(
            switchMap((org) =>
                this.apiWebhooksService.deleteWebhookByIDForParty(this.idGenerator.shortUuid(), org.party, webhookID)
            )
        );
    }
}
