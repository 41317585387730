import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { coerceBoolean } from '@dsh/utils';

import { LanguageService } from '../../language';

@Component({
    selector: 'dsh-actionbar',
    templateUrl: 'actionbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionbarComponent {
    @Input() @coerceBoolean inverted = false;

    constructor(public translationService: LanguageService) {}
}
