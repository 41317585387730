import { SectionLink } from '../model';

export const createLinks = (
    // { claims, main, payments, wallets }: { [k: string]: string },
    { claims, main, payments }: { [k: string]: string },
    hasWallets: boolean,
    hideMain: boolean = false
): SectionLink[] =>
    [
        {
            label: payments,
            path: `/payment-section`
        },
        /*
                hasWallets && {
                    label: wallets,
                    path: '/wallet-section',
                },
        */
        {
            label: claims,
            path: '/claim-section'
        },
        !hideMain && {
            label: main,
            path: '/',
            exact: true
        }
    ].filter(Boolean);
