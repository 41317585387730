import { Injectable } from '@angular/core';
import { IdGeneratorService } from '@xpay_rbk/id-generator';
import { switchMap } from 'rxjs/operators';

import {
    InvoiceTemplateCreateParams,
    InvoiceTemplatesService as InvoiceTemplatesApiService,
} from '@dsh/api-codegen/capi';
import { OrganizationManagementService } from '@dsh/app/shared/services/organization-management';

@Injectable()
export class InvoiceTemplatesService {
    constructor(
        private invoiceTemplatesService: InvoiceTemplatesApiService,
        private idGenerator: IdGeneratorService,
        private organizationManagementService: OrganizationManagementService
    ) {}

    createInvoiceTemplate(params: InvoiceTemplateCreateParams) {
        return this.organizationManagementService.organization$.pipe(
            switchMap((org) =>
                this.invoiceTemplatesService.createInvoiceTemplate(this.idGenerator.shortUuid(), {
                    ...params,
                    partyID: org.party,
                })
            )
        );
    }

    getInvoicePaymentMethodsByTemplateID(invoiceTemplateID: string) {
        return this.invoiceTemplatesService.getInvoicePaymentMethodsByTemplateID(
            this.idGenerator.shortUuid(),
            invoiceTemplateID
        );
    }
}
