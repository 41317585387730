import { Injectable } from '@angular/core';
import { IdGeneratorService } from '@xpay_rbk/id-generator';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Contract, ContractsService as ContractsAPIService } from '@dsh/api-codegen/capi/swagger-codegen';
import { ContextService } from '@dsh/app/shared/services/context';

@Injectable()
export class ContractsService {
    constructor(
        private contractsService: ContractsAPIService,
        private idGenerator: IdGeneratorService,
        private contextService: ContextService
    ) {}

    getContractByID(contractID: string): Observable<Contract> {
        return this.contextService.organization$.pipe(
            switchMap((context) =>
                this.contractsService.getContractByIDForParty(this.idGenerator.shortUuid(), contractID, context.party)
            )
        );
    }
}
