import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    private _isLoaderEnabled: boolean;
    private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private counterOfActiveRequests: number = 0;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    readonly isLoading$ = this.isLoadingSubject.asObservable();

    init(isLoaderEnabled: boolean): void {
        this._isLoaderEnabled = isLoaderEnabled;
    }

    get isLoaderEnabled(): boolean {
        return this._isLoaderEnabled;
    }

    showLoader(): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !this.counterOfActiveRequests && this.isLoadingSubject.next(true);
        this.counterOfActiveRequests++;
    }

    hideLoader(): void {
        this.counterOfActiveRequests--;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setTimeout(() => !this.counterOfActiveRequests && this.isLoadingSubject.next(false), 300);
    }
}
