import { InjectionToken } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { ChartsThemeProvider } from './payment-section/analytics/charts-theme';

export const SEARCH_LIMIT = new InjectionToken<number>('searchLimit');
export const DEFAULT_SEARCH_LIMIT = 10;

export type DialogConfig = { small: MatDialogConfig; medium: MatDialogConfig; large: MatDialogConfig };
export const DIALOG_CONFIG = new InjectionToken<DialogConfig>('dialogConfig');
const BASE_CONFIG: MatDialogConfig = {
    ...new MatDialogConfig(),
    maxHeight: '90vh',
    disableClose: true,
    autoFocus: false,
    panelClass: 'dsh-dialog-pane'
};
export const DEFAULT_DIALOG_CONFIG: DialogConfig = {
    small: { ...BASE_CONFIG, width: '360px' },
    medium: { ...BASE_CONFIG, width: '552px' },
    large: { ...BASE_CONFIG, width: '648px' }
};

export const DEFAULT_CHARTS_THEME: ChartsThemeProvider = {
    stackedBarChart: [
        '#9EF996',
        '#FFDE68',
        '#FF6CAA',
        '#9E9E9E'
    ],
    barChart: [
        '#AAA0FA'
    ],
    donutChart: [
        '#9EF996',
        '#FFDE68',
        '#FF6CAA',
        '#AAA0FA',
        '#9E9E9E',
        '#38C1CD',
        '#5B9FFF',
        '#FFAB91',
        '#FC9B51',
        '#695BFF'
    ]
};
