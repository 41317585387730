import { NgModule } from '@angular/core';

import { MobileGridModule } from './mobile-grid.module';
import { XpayMobileMenuModule } from './mobile-menu/xpay-mobile-menu.module';
import { XpayMobileGridComponent } from './xpay-mobile-grid.component';

@NgModule({
    imports: [MobileGridModule, XpayMobileMenuModule],
    declarations: [XpayMobileGridComponent],
    exports: [XpayMobileGridComponent],
})
export class XpayMobileGridModule {}
