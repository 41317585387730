<div class="button-wrapper" [ngClass]="{ 'button-wrapper-disabled': disabled }">
    <div *ngIf="(isMobile$ | async) === false" class="dropdown-trigger" [dshDropdownTriggerFor]="dropdown"></div>
    <dsh-filter-button [disabled]="disabled" [active]="active" (click)="open()">
        {{ active ? activeLabel : label }}
    </dsh-filter-button>
</div>

<dsh-dropdown
    #dropdown="dshDropdown"
    hasArrow="false"
    position="left"
    offset="16px 0 0"
    width="360px"
    (closed)="save.emit()"
>
    <ng-template>
        <dsh-filter-content (save)="dropdown.close()" (clear)="clear.emit()" [noClearButton]="noClearButton">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </dsh-filter-content>
    </ng-template>
</dsh-dropdown>
