<div
    *transloco="let t; scope: 'api-model-details'; read: 'apiModelDetails.internationalLegalEntity'"
    gdColumns="1fr"
    gdColumns.gt-sm="1fr 1fr 1fr"
    gdGap="24px"
>
    <dsh-details-item [title]="t('legalName')">
        {{ internationalLegalEntity?.legalName }}
    </dsh-details-item>
    <dsh-details-item *ngIf="internationalLegalEntity?.tradingName" [title]="t('tradingName')">
        {{ internationalLegalEntity?.tradingName }}
    </dsh-details-item>
    <dsh-details-item *ngIf="internationalLegalEntity?.registeredNumber" [title]="t('registeredNumber')">
        {{ internationalLegalEntity?.registeredNumber }}
    </dsh-details-item>
    <dsh-details-item gdColumn.gt-sm="1/-1" [title]="t('registeredOffice')">
        {{ internationalLegalEntity?.registeredOffice }}
    </dsh-details-item>
</div>
