import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, defer, Observable, of, ReplaySubject, BehaviorSubject } from 'rxjs';
import { catchError, map, pluck, switchMap } from 'rxjs/operators';

import { OrganizationsService } from '@dsh/api';
import { Member, Organization, RoleId } from '@dsh/api-codegen/organizations';
import { ErrorService, KeycloakTokenInfoService } from '@dsh/app/shared';
import { Initializable } from '@dsh/app/shared/types';
import { publishReplayRefCount } from '@dsh/operators';

@Injectable({ providedIn: 'root' })
export class OrganizationManagementService implements Initializable {
    currentMember$: Observable<Member> = defer(() =>
        combineLatest([this.organization$, this.keycloakTokenInfoService.partyID$])
    ).pipe(
        switchMap(([{ id: orgId, owner }, userId]) => {
            if (owner === userId) {
                return of<Member>({ id: userId, userEmail: '', roles: [] });
            }
            return this.organizationsService.getOrgMember(orgId, userId).pipe(
                catchError((error) => {
                    if (!(error instanceof HttpErrorResponse && error.status === 404)) {
                        this.errorService.error(error);
                    }
                    return of<Member>({ id: userId, userEmail: '', roles: [] });
                })
            );
        }),
        publishReplayRefCount()
    );
    members$: Observable<Member[]> = defer(() => this.organization$).pipe(
        switchMap(({ id }) => this.organizationsService.listOrgMembers(id)),
        pluck('result'),
        publishReplayRefCount()
    );
    isOrganizationOwner$: Observable<boolean> = defer(() =>
        combineLatest([this.organization$, this.keycloakTokenInfoService.partyID$])
    ).pipe(
        map(([{ owner }, id]) => owner === id),
        publishReplayRefCount()
    );
    isOrganizationAdmin$: Observable<boolean> = defer(() => this.currentMember$).pipe(
        map((member) => member.roles.findIndex((r) => r.roleId === RoleId.Administrator) !== -1),
        publishReplayRefCount()
    );
    hasAdminAccess$: Observable<boolean> = defer(() =>
        combineLatest([this.isOrganizationAdmin$, this.isOrganizationOwner$])
    ).pipe(
        map((hasAdminLikeRoles) => hasAdminLikeRoles.includes(true)),
        publishReplayRefCount()
    );

    isOrganizationManager$: Observable<boolean> = defer(() => this.currentMember$).pipe(
        map((member) => member.roles.findIndex((r) => r.roleId === RoleId.Manager) !== -1),
        publishReplayRefCount()
    );

    isOrganizationAccountant$: Observable<boolean> = defer(() => this.currentMember$).pipe(
        map((member) => member.roles.findIndex((r) => r.roleId === RoleId.Accountant) !== -1),
        publishReplayRefCount()
    );

    isOrganizationIntegrator$: Observable<boolean> = defer(() => this.currentMember$).pipe(
        map((member) => member.roles.findIndex((r) => r.roleId === RoleId.Integrator) !== -1),
        publishReplayRefCount()
    );

    isIntegratorOrAccountant$ = combineLatest([this.isOrganizationIntegrator$, this.isOrganizationAccountant$]).pipe(
        map((array) => array.some((el) => el))
    );

    isManagerAndAccountant$ = combineLatest([this.isOrganizationManager$, this.isOrganizationAccountant$]).pipe(
        map((array) => array.every((el) => el))
    );

    organization$ = new ReplaySubject<Organization>();

    partyID$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private organizationsService: OrganizationsService,
        private keycloakTokenInfoService: KeycloakTokenInfoService,
        private errorService: ErrorService
    ) {}

    get currentPartyId(): string {
        return this.partyID$.getValue();
    }

    init(organization: Organization): void {
        this.organization$.next(organization);
        this.partyID$.next(organization.party);
    }
}
