<dsh-language-selector
    style="margin-right: 10px"
    *ngIf="translationService.getAvailableLanguages().length > 1"
></dsh-language-selector>
<dsh-user-dropdown
    [dshDropdownTriggerFor]="userDropdown"
    [expanded]="(userDropdown.state$ | async) === 'open'"
    [inverted]="inverted"
></dsh-user-dropdown>

<dsh-dropdown width="287px" #userDropdown="dshDropdown" [hasArrow]="false" position="left">
    <ng-template><dsh-user (selected)="userDropdown.close()"></dsh-user></ng-template>
</dsh-dropdown>
