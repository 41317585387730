import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

import { animation300ms } from '@dsh/app/shared/animations/in-out-300.animation';

import { LoaderService } from '../loader/loader.service';
import { ThemeManager } from '../theme-manager';
import { HomeComponent } from './home.component';

@UntilDestroy()
@Component({
    selector: 'dsh-home',
    templateUrl: 'xpay-home.component.html',
    styleUrls: ['./xpay-home.component.scss'],
    animations: [animation300ms],
})
export class XpayHomeComponent extends HomeComponent {
    constructor(
        protected router: Router,
        // need to create class when home component was init
        protected themeManager: ThemeManager,
        protected breakpointObserver: BreakpointObserver,
        public loaderService: LoaderService
    ) {
        super(router, themeManager, breakpointObserver);
    }
}
