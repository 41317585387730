/**
 * RBKmoney Questionary aggr proxy API
 *  Proxy service for DaData/KonturFocus API 
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@rbk.money
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type OrgType = 'Legal' | 'Individual';

export const OrgType = {
    Legal: 'Legal' as OrgType,
    Individual: 'Individual' as OrgType
};
