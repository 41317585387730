import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaymentInstitution } from '@dsh/api-codegen/capi';
import { SectionsLinksService } from '@dsh/app/shared';

import { NavbarItemConfig, toNavbarItemConfig } from '../../../sections/payment-section/utils';
import { MobileMenuComponent } from './mobile-menu.component';

@UntilDestroy()
@Component({
    selector: 'dsh-mobile-menu',
    templateUrl: './xpay-mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
})
export class XpayMobileMenuComponent extends MobileMenuComponent implements OnInit {
    navbarItemConfig$: Observable<NavbarItemConfig[]> = this.transloco
        .selectTranslateObject<{ [k: string]: string }>('nav', {}, 'payment-section')
        .pipe(map(toNavbarItemConfig));

    realmChange$ = new BehaviorSubject<PaymentInstitution.RealmEnum>(PaymentInstitution.RealmEnum.Live);
    private activeSectionChange$ = new BehaviorSubject<string>('shops');

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private transloco: TranslocoService,
        private sectionsLinksServiceComp: SectionsLinksService
    ) {
        super(sectionsLinksServiceComp);
    }

    ngOnInit(): void {
        combineLatest([this.activeSectionChange$, this.realmChange$])
            .pipe(untilDestroyed(this))
            .subscribe(([activeSection, realm]) => {
                return void this.router.navigate(['payment-section', 'realm', realm, activeSection]);
            });
    }

    setActiveSection(section: string): void {
        this.activeSectionChange$.next(section);
        this.menuItemSelected.next();
    }

    testEnvToggle(): void {
        const isLive = this.router.url.includes('realm/live');
        const realmNew = isLive ? PaymentInstitution.RealmEnum.Test : PaymentInstitution.RealmEnum.Live;
        this.realmChange$.next(realmNew);
        this.menuItemSelected.next();
    }

    isActive(route: string): boolean {
        return this.router.url.includes(route);
    }
}
