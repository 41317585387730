/**
 * RBKmoney Questionary Resource API
 *  RBKmoney Questionary Resource API для работы с анкетами. 
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@rbk.money
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface LegalEntity { 
    legalEntityType: LegalEntity.LegalEntityTypeEnum;
}
export namespace LegalEntity {
    export type LegalEntityTypeEnum = 'RussianLegalEntity' | 'InternationalLegalEntity';
    export const LegalEntityTypeEnum = {
        RussianLegalEntity: 'RussianLegalEntity' as LegalEntityTypeEnum,
        InternationalLegalEntity: 'InternationalLegalEntity' as LegalEntityTypeEnum
    };
}
