<mat-divider></mat-divider>

<div *transloco="let t" class="actions">
    <ng-content></ng-content>
    <div *ngIf="!withoutClear; else saveOnly" fxFlex fxLayoutAlign="space-between" fxLayoutGap="20px">
        <div>
            <button dsh-stroked-button color="accent" (click)="clear.emit($event)">{{ t('clear') }}</button>
        </div>
        <div>
            <button dsh-button color="accent" (click)="save.emit($event)">{{ t('save') }}</button>
        </div>
    </div>
    <ng-template #saveOnly>
        <button fxFlex dsh-button color="accent" (click)="save.emit($event)">{{ t('save') }}</button>
    </ng-template>
</div>
