import { Injectable } from '@angular/core';
import { IdGeneratorService } from '@xpay_rbk/id-generator';
import sortBy from 'lodash-es/sortBy';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Country, DictionariesService } from '@dsh/api-codegen/capi/swagger-codegen';
import { ErrorService } from '@dsh/app/shared/services';
import { shareReplayRefCount } from '@dsh/operators';

@Injectable({ providedIn: 'root' })
export class CountriesService {
    countries$: Observable<Country[]> = this.getCountries().pipe(
        catchError((error) => {
            this.errorService.error(error, false);
            return of([]);
        }),
        shareReplayRefCount<Country[]>()
    );

    constructor(
        private dictionariesService: DictionariesService,
        private idGenerator: IdGeneratorService,
        private errorService: ErrorService
    ) {}

    getCountries(): Observable<Country[]> {
        return this.dictionariesService
            .getCountries(this.idGenerator.shortUuid())
            .pipe(map((countries) => sortBy(countries, 'alpha3')));
    }
}
