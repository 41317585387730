<div
    *transloco="let t; scope: 'api-model-details'; read: 'apiModelDetails.contract'"
    fxLayout="column"
    fxLayoutGap="24px"
>
    <dsh-details-item [title]="t('createdAt')">
        {{ contract?.createdAt | localizedDate: 'd MMMM y, HH:mm:ss' }}
    </dsh-details-item>
    <dsh-contractor-details [contractor]="contract?.contractor"></dsh-contractor-details>
</div>
