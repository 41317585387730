import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountsService } from './api/accounts.service';
import { CategoriesService } from './api/categories.service';
import { ContractsService } from './api/contracts.service';
import { CustomersService } from './api/customers.service';
import { DictionariesService } from './api/dictionaries.service';
import { GeoService } from './api/geo.service';
import { InvoiceTemplatesService } from './api/invoiceTemplates.service';
import { InvoicesService } from './api/invoices.service';
import { PartiesService } from './api/parties.service';
import { PaymentInstitutionsService } from './api/paymentInstitutions.service';
import { PaymentsService } from './api/payments.service';
import { PayoutsService } from './api/payouts.service';
import { ShopsService } from './api/shops.service';
import { TokensService } from './api/tokens.service';
import { WebhooksService } from './api/webhooks.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountsService,
    CategoriesService,
    ContractsService,
    CustomersService,
    DictionariesService,
    GeoService,
    InvoiceTemplatesService,
    InvoicesService,
    PartiesService,
    PaymentInstitutionsService,
    PaymentsService,
    PayoutsService,
    ShopsService,
    TokensService,
    WebhooksService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
