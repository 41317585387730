import { Injectable } from '@angular/core';
import { IdGeneratorService } from '@xpay_rbk/id-generator';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
    Payout,
    PayoutParams,
    PayoutsService as PayoutsAPIService,
    PayoutTool,
} from '@dsh/api-codegen/capi/swagger-codegen';
import { ContextService } from '@dsh/app/shared/services/context';

@Injectable()
export class PayoutsService {
    constructor(
        private payoutsService: PayoutsAPIService,
        private idGenerator: IdGeneratorService,
        private contextService: ContextService
    ) {}

    createPayout(params: PayoutParams): Observable<Payout> {
        return this.payoutsService.createPayout(this.idGenerator.shortUuid(), params);
    }

    getPayoutTools(contractID: string): Observable<PayoutTool[]> {
        return this.payoutsService.getPayoutTools(this.idGenerator.shortUuid(), contractID);
    }

    getPayoutToolByID(contractID: string, payoutToolID: string): Observable<PayoutTool> {
        return this.contextService.organization$.pipe(
            switchMap((context) =>
                this.payoutsService.getPayoutToolByIDForParty(
                    this.idGenerator.shortUuid(),
                    contractID,
                    payoutToolID,
                    context.party
                )
            )
        );
    }
}
