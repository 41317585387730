import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CurrenciesService } from './api/currencies.service';
import { DepositsService } from './api/deposits.service';
import { DownloadsService } from './api/downloads.service';
import { IdentitiesService } from './api/identities.service';
import { ProvidersService } from './api/providers.service';
import { ReportsService } from './api/reports.service';
import { ResidencesService } from './api/residences.service';
import { W2WService } from './api/w2W.service';
import { WalletsService } from './api/wallets.service';
import { WebhooksService } from './api/webhooks.service';
import { WithdrawalsService } from './api/withdrawals.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CurrenciesService,
    DepositsService,
    DownloadsService,
    IdentitiesService,
    ProvidersService,
    ReportsService,
    ResidencesService,
    W2WService,
    WalletsService,
    WebhooksService,
    WithdrawalsService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
