<div
    *transloco="let t; scope: 'api-model-details'; read: 'apiModelDetails.russianLegalEntity'"
    gdColumns="1fr"
    gdColumns.gt-sm="1fr 1fr 1fr"
    gdGap="24px"
>
    <dsh-details-item [title]="t('registeredName')">
        {{ russianLegalEntity?.registeredName }}
    </dsh-details-item>
    <dsh-details-item [title]="t('inn')">
        {{ russianLegalEntity?.inn }}
    </dsh-details-item>
    <dsh-details-item [title]="t('registeredNumber')">
        {{ russianLegalEntity?.registeredNumber }}
    </dsh-details-item>
    <dsh-details-item gdColumn.gt-sm="1/-1" [title]="t('actualAddress')">
        {{ russianLegalEntity?.actualAddress }}
    </dsh-details-item>
    <dsh-details-item [title]="t('representativePosition')">
        {{ russianLegalEntity?.representativePosition }}
    </dsh-details-item>
    <dsh-details-item [title]="t('representativeFullName')">
        {{ russianLegalEntity?.representativeFullName }}
    </dsh-details-item>
    <dsh-details-item [title]="t('representativeDocument')">
        {{ russianLegalEntity?.representativeDocument }}
    </dsh-details-item>
    <mat-divider gdColumn.gt-sm="1/-1"></mat-divider>
    <dsh-bank-account-details
        gdColumn.gt-sm="1/-1"
        [bankAccount]="russianLegalEntity?.bankAccount"
    ></dsh-bank-account-details>
</div>
