import { Component, Input, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

import { coerceBoolean } from '@dsh/utils';

import { MobileGridComponent } from './mobile-grid.component';

@Component({
    selector: 'dsh-mobile-grid',
    templateUrl: './xpay-mobile-grid.component.html',
    styleUrls: ['./mobile-grid.component.scss'],
})
export class XpayMobileGridComponent extends MobileGridComponent {
    @Input()
    @coerceBoolean
    inverted: boolean;

    @Input()
    logoName: string;

    @ViewChild(MatDrawer)
    drawer: MatDrawer;
}
