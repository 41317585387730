import { Injectable } from '@angular/core';
import { IdGeneratorService } from '@xpay_rbk/id-generator';
import { defer, Observable, Subject, forkJoin, combineLatest, of } from 'rxjs';
import { startWith, switchMapTo, switchMap } from 'rxjs/operators';

import { Shop } from '@dsh/api-codegen/capi';
import { ShopsService } from '@dsh/api-codegen/capi/shops.service';
import { ContextService } from '@dsh/app/shared/services/context';
import { OrganizationManagementService } from '@dsh/app/shared/services/organization-management';
import { shareReplayRefCount } from '@dsh/operators';

import { OrganizationsService } from '../organizations';

@Injectable()
export class ApiShopsService {
    shops$: Observable<Shop[]> = defer(() => this.reloadShops$).pipe(
        startWith<void, null>(null),
        switchMapTo(
            combineLatest([
                this.organizationManagementService.currentMember$,
                this.organizationManagementService.isOrganizationAdmin$,
            ])
        ),
        switchMap(([member, isAdmin]) => {
            if (!member.roles.length || isAdmin) return this.getShopsForParty();

            const shops = [
                ...new Set(
                    member.roles.filter((role) => role.scope.id === 'Shop').map((role) => role.scope.resourceId)
                ),
            ];

            return this.organizationManagementService.partyID$.pipe(
                switchMap((party) => {
                    const shopsObservableArray = shops.map((id) =>
                        this.shopsService.getShopByIDForParty(this.idGenerator.shortUuid(), id, party)
                    );
                    return shopsObservableArray.length ? forkJoin([...shopsObservableArray]) : of([] as Shop[]);
                })
            );
        }),
        shareReplayRefCount()
    );

    shopsForSelectRoleToOrg$: Observable<Shop[]> = this.organizationsService.selectedParty.pipe(
        switchMap((party) => {
            if (!party) return of([] as Shop[]);
            return this.shopsService.getShopsForParty(this.idGenerator.shortUuid(), party);
        }),
        shareReplayRefCount()
    );

    private reloadShops$ = new Subject<void>();

    constructor(
        private shopsService: ShopsService,
        private idGenerator: IdGeneratorService,
        private contextService: ContextService,
        private organizationManagementService: OrganizationManagementService,
        private organizationsService: OrganizationsService
    ) {}

    getShopByIDForParty(shopID: string): Observable<Shop> {
        return this.contextService.organization$.pipe(
            switchMap((org) => this.shopsService.getShopByIDForParty(this.idGenerator.shortUuid(), shopID, org.party))
        );
    }

    getShopsForParty(): Observable<Shop[]> {
        return this.contextService.organization$.pipe(
            switchMap((org) => this.shopsService.getShopsForParty(this.idGenerator.shortUuid(), org.party))
        );
    }

    reloadShops(): void {
        this.reloadShops$.next();
    }

    suspendShop(shopID: string): Observable<void> {
        return this.contextService.organization$.pipe(
            switchMap((org) => this.shopsService.suspendShopForParty(this.idGenerator.shortUuid(), shopID, org.party))
        );
    }

    activateShop(shopID: string): Observable<void> {
        return this.contextService.organization$.pipe(
            switchMap((org) => this.shopsService.activateShopForParty(this.idGenerator.shortUuid(), shopID, org.party))
        );
    }
}
