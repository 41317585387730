import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
    Conversation,
    ConversationParam,
    ConversationResponse,
    ConversationsService,
    ConversationStatus,
} from '@dsh/api-codegen/messages';
import { OrganizationManagementService } from '@dsh/app/shared/services/organization-management';

@Injectable()
export class MessagesService {
    constructor(
        private conversationsService: ConversationsService,
        private organizationManagementService: OrganizationManagementService
    ) {}

    getConversations(
        conversationId: Conversation['conversationId'][],
        conversationStatus?: ConversationStatus
    ): Observable<ConversationResponse> {
        return this.organizationManagementService.partyID$.pipe(
            switchMap((partyID) =>
                this.conversationsService.getConversations(conversationId, partyID, conversationStatus)
            )
        );
    }

    saveConversations(params: ConversationParam[], partyId: string): Observable<any> {
        return this.conversationsService.saveConversations(partyId, params);
    }
}
