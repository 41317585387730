import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OrganizationManagementService } from '@dsh/app/shared/services/organization-management';
import { SectionsLinksService } from '@dsh/app/shared/services/sections-links';
import { coerceBoolean } from '@dsh/utils';

@Component({
    selector: 'dsh-toolbar',
    templateUrl: 'toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {
    @Input() @coerceBoolean inverted: boolean;
    @Input() logoName: string;

    sectionLinks$ = this.sectionsLinksService.sectionLinks$;
    hasManagerAccess$ = this.organizationManagementService.isOrganizationManager$;
    hasAccountantAccess$ = this.organizationManagementService.isOrganizationAccountant$;
    hasIntegratorAccess$ = this.organizationManagementService.isOrganizationIntegrator$;

    constructor(
        private sectionsLinksService: SectionsLinksService,
        private organizationManagementService: OrganizationManagementService
    ) {}
}
