import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { RouterModule } from '@angular/router';

import { XPAY_DATE_FORMAT } from '../../date.constants';
import { RouteNavbarLayoutComponent } from './route-navbar-layout.component';

@NgModule({
    imports: [CommonModule, RouterModule, FlexLayoutModule],
    declarations: [RouteNavbarLayoutComponent],
    exports: [RouteNavbarLayoutComponent],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: XPAY_DATE_FORMAT }],
})
export class RouteNavbarLayoutModule {}
