import { NgModule } from '@angular/core';

import { ClaimsService } from '../claim-management/swagger-codegen';
import { CapiConfigService } from './capi-config.service';
import { InvoicesService } from './invoices.service';
import { ShopsService } from './shops.service';
import { ApiModule, Configuration, PartiesService, PayoutsService, DictionariesService } from './swagger-codegen';

@NgModule({
    imports: [
        {
            ngModule: ApiModule,
            providers: [{ provide: Configuration, useClass: CapiConfigService }],
        },
    ],
    providers: [
        CapiConfigService,
        ClaimsService,
        ShopsService,
        InvoicesService,
        PayoutsService,
        PartiesService,
        DictionariesService,
    ],
})
export class CapiModule {}
