/**
 * RBKmoney Questionary aggr proxy API
 *  Proxy service for DaData/KonturFocus API 
 *
 * OpenAPI spec version: 0.0.1
 * Contact: support@rbk.money
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Тело запроса в DaData
 */
export interface DaDataRequest { 
    daDataRequestType: DaDataRequest.DaDataRequestTypeEnum;
}
export namespace DaDataRequest {
    export type DaDataRequestTypeEnum = 'AddressQuery' | 'PartyQuery' | 'BankQuery' | 'FioQuery' | 'FmsUnitQuery' | 'OkvedQuery';
    export const DaDataRequestTypeEnum = {
        AddressQuery: 'AddressQuery' as DaDataRequestTypeEnum,
        PartyQuery: 'PartyQuery' as DaDataRequestTypeEnum,
        BankQuery: 'BankQuery' as DaDataRequestTypeEnum,
        FioQuery: 'FioQuery' as DaDataRequestTypeEnum,
        FmsUnitQuery: 'FmsUnitQuery' as DaDataRequestTypeEnum,
        OkvedQuery: 'OkvedQuery' as DaDataRequestTypeEnum
    };
}
