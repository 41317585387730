import { Pipe, PipeTransform } from '@angular/core';

import { SectionLink } from '@dsh/app/shared';

@Pipe({
    name: 'hideApplicationLink'
})
export class HideApplicationLinkPipe implements PipeTransform {
    transform(links: SectionLink[], blockAccess: boolean): SectionLink[] {
        return !blockAccess ? links : links?.filter((link) => link.path !== '/claim-section');
    }
}
